import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "pt-3" }
const _hoisted_2 = { class: "grid pr-2 pl-2" }
const _hoisted_3 = { class: "flex col-12 justify-content-end pl-0 pr-0" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "grid mt-1 mb-2" }
const _hoisted_6 = { class: "grid col-12 md:col-8 xl:col-6 m-0 p-0 flex align-content-start justify-content-start" }
const _hoisted_7 = { class: "col-12 md:col-6 p-1" }
const _hoisted_8 = { class: "p-inputgroup" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "p-error" }
const _hoisted_11 = { class: "col-12 md:col-6 p-1" }
const _hoisted_12 = { class: "p-inputgroup" }
const _hoisted_13 = { class: "col-12 md:col-6 p-1" }
const _hoisted_14 = { class: "p-inputgroup" }
const _hoisted_15 = { class: "col-12 md:col-6 p-1" }
const _hoisted_16 = { class: "p-inputgroup" }
const _hoisted_17 = { class: "col-12 md:col-6 p-1" }
const _hoisted_18 = { class: "p-inputgroup" }
const _hoisted_19 = { class: "col-12 md:col-6 p-1" }
const _hoisted_20 = { class: "p-inputgroup" }
const _hoisted_21 = { class: "col-12 md:col-6 p-1" }
const _hoisted_22 = { class: "p-inputgroup" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "col-12 md:col-6 p-1" }
const _hoisted_25 = { class: "p-inputgroup" }
const _hoisted_26 = { class: "grid col-12 md:col-4 xl:col-6 m-0 p-0 flex align-content-start justify-content-start" }
const _hoisted_27 = { class: "col-12 p-1" }
const _hoisted_28 = { class: "col-12 xl:col-6 m-0 p-0" }
const _hoisted_29 = { class: "grid mt-1 mb-2" }
const _hoisted_30 = { class: "col-12 md:col-6 lg:col-4 xl:col-3 p-1" }
const _hoisted_31 = { class: "p-inputgroup" }
const _hoisted_32 = { class: "col-12 md:col-6 lg:col-4 xl:col-3 p-1" }
const _hoisted_33 = { class: "p-inputgroup" }
const _hoisted_34 = { class: "col-12 md:col-6 lg:col-4 xl:col-3 p-1" }
const _hoisted_35 = { class: "p-inputgroup" }
const _hoisted_36 = { class: "col-12 md:col-6 lg:col-4 xl:col-3 p-1" }
const _hoisted_37 = { class: "p-inputgroup" }
const _hoisted_38 = { class: "col-12 md:col-6 lg:col-4 xl:col-3 p-1" }
const _hoisted_39 = { class: "p-inputgroup" }
const _hoisted_40 = { class: "col-12 md:col-6 lg:col-4 xl:col-3 p-1" }
const _hoisted_41 = { class: "p-inputgroup" }
const _hoisted_42 = { class: "grid mt-1 mb-2" }
const _hoisted_43 = { class: "col-12 md:col-6 lg:col-4 xl:col-3 p-1" }
const _hoisted_44 = { class: "p-inputgroup" }
const _hoisted_45 = { class: "col-12 md:col-6 lg:col-4 xl:col-3 p-1" }
const _hoisted_46 = { class: "p-inputgroup" }
const _hoisted_47 = { class: "col-12 md:col-6 lg:col-4 xl:col-3 p-1" }
const _hoisted_48 = { class: "p-inputgroup" }
const _hoisted_49 = { class: "col-12 md:col-6 lg:col-4 xl:col-3 p-1" }
const _hoisted_50 = { class: "p-inputgroup" }
const _hoisted_51 = { class: "grid mt-1 mb-2" }
const _hoisted_52 = { class: "lg:col-4 col-12 pt-0 pb-0 mt-0 mb-0" }
const _hoisted_53 = { class: "lg:col-4 col-12 pt-0 pb-0 mt-0 mb-0" }
const _hoisted_54 = { class: "lg:col-4 col-12 pt-0 pb-0 mt-0 mb-0" }
const _hoisted_55 = { class: "col-12 pl-1 pr-1" }
const _hoisted_56 = { class: "flex justify-content-between align-items-center p-0" }
const _hoisted_57 = { class: "grid p-0 m-0 align-items-center" }
const _hoisted_58 = { class: "col-12 pt-0 pb-0 grid justify-content-between align-items-center" }
const _hoisted_59 = {
  key: 1,
  class: "col-11 lineitem-number"
}
const _hoisted_60 = ["onClick"]
const _hoisted_61 = ["onClick"]
const _hoisted_62 = ["onClick"]
const _hoisted_63 = { class: "col-12 pt-0 pb-0 grid justify-content-between align-items-center" }
const _hoisted_64 = { class: "col-11 description lineitem-description lg:max-w-max" }
const _hoisted_65 = ["onClick"]
const _hoisted_66 = { key: 1 }
const _hoisted_67 = { class: "col-12 p-1 m-0 pb-4" }
const _hoisted_68 = { class: "col-12 p-1 m-0 pb-4" }
const _hoisted_69 = {
  key: 0,
  class: "p-error"
}
const _hoisted_70 = {
  key: 1,
  class: "p-error"
}
const _hoisted_71 = {
  key: 0,
  class: "p-error"
}
const _hoisted_72 = {
  key: 1,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_SearchFile = _resolveComponent("SearchFile")!
  const _component_ContactAutocomplete = _resolveComponent("ContactAutocomplete")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Search = _resolveComponent("Search")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AddressItems = _resolveComponent("AddressItems")!
  const _component_CollapsibleSection = _resolveComponent("CollapsibleSection")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_RepDataTable = _resolveComponent("RepDataTable")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Row = _resolveComponent("Row")!
  const _component_ColumnGroup = _resolveComponent("ColumnGroup")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_LogsDataTable = _resolveComponent("LogsDataTable")!
  const _component_AttachmentRecords = _resolveComponent("AttachmentRecords")!
  const _component_Card = _resolveComponent("Card")!
  const _component_SearchPartDialog = _resolveComponent("SearchPartDialog")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_EmailFileDialog = _resolveComponent("EmailFileDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_SplitButton, {
            label: "Save",
            icon: "pi pi-check",
            onClick: _ctx.submit,
            model: _ctx.topSplitButtonItems
          }, null, 8, ["onClick", "model"])
        ]),
        _createVNode(_component_Card, { class: "col-12" }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_CollapsibleSection, {
                isOpen: _ctx.sectionsStatus.customerDetails,
                header: "Customer Details",
                "onUpdate:isOpen": _cache[8] || (_cache[8] = ($event: any) => (_ctx.toggleSectionIcon('customerDetails')))
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _cache[47] || (_cache[47] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '104px' }
                          }, " Customer ID ", -1)),
                          _createVNode(_component_SearchFile, {
                            inputValue: _ctx.soQuote.customer,
                            ref: "cust",
                            field_id: "cust_id",
                            file: "CUST",
                            placeHolder: _ctx.soQuote.customer,
                            onSelectOption: _ctx.updateCustomer,
                            showButton: true,
                            columns: [
                            { field: 'cust_id', header: 'Customer ID' },
                            { field: 'name', header: 'Customer Name' },
                          ]
                          }, null, 8, ["inputValue", "placeHolder", "onSelectOption"])
                        ]),
                        (
                          _ctx.isFormSubmitted && _ctx.v$.newSoQuoteCust.required.$invalid
                        )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createElementVNode("small", _hoisted_10, _toDisplayString(_ctx.v$.newSoQuoteCust.required.$message), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _cache[48] || (_cache[48] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Contact", -1)),
                          _createVNode(_component_ContactAutocomplete, {
                            "selected-id": _ctx.soQuote.contact_id,
                            onItemSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleContactSelected($event))),
                            "contact-lookup": _ctx.getContactLookup,
                            "cust-id": _ctx.soQuote.customer
                          }, null, 8, ["selected-id", "contact-lookup", "cust-id"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _cache[49] || (_cache[49] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, " Customer Name ", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.soQuote.name,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.soQuote.name) = $event)),
                            readOnly: ""
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _cache[50] || (_cache[50] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Email", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.soQuote.email,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.soQuote.email) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _cache[51] || (_cache[51] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Prospect Id", -1)),
                          _createVNode(_component_Search, {
                            modelValue: _ctx.prospectName,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.prospectName) = $event)),
                            onFilter: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setProspect($event.value))),
                            placeHolder: "Search for a Prospect",
                            label: "name",
                            file: "PROSPECT",
                            minimumCharacters: 2
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          _cache[52] || (_cache[52] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Phone", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.soQuote.phone,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.soQuote.phone) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          _cache[53] || (_cache[53] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Company", -1)),
                          _createVNode(_component_Dropdown, {
                            modelValue: _ctx.soQuote.co_code,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.soQuote.co_code) = $event)),
                            options: _ctx.customerInquiry.coCodes,
                            optionLabel: "name",
                            optionValue: "code",
                            class: _normalizeClass({
                            'p-invalid':
                              _ctx.isFormSubmitted && _ctx.v$.co_code.required.$invalid,
                          })
                          }, null, 8, ["modelValue", "options", "class"])
                        ]),
                        (
                          _ctx.isFormSubmitted &&
                          _ctx.v$.soQuote.co_code.required.$invalid
                        )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_23, _cache[54] || (_cache[54] = [
                              _createElementVNode("small", { class: "p-error" }, "The value is required", -1)
                            ])))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, [
                          _cache[55] || (_cache[55] = _createElementVNode("span", {
                            class: "p-inputgroup-addon",
                            style: { width: '103px' }
                          }, "Fax", -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.soQuote.fax,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.soQuote.fax) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _cache[56] || (_cache[56] = _createElementVNode("span", {
                        class: "col-12 flex justify-content-start align-items-center",
                        style: {"height":"45px"}
                      }, [
                        _createElementVNode("strong", null, "Address")
                      ], -1)),
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("div", _hoisted_28, [
                          _createVNode(_component_AddressItems, {
                            address: _ctx.soQuote.address_items,
                            inline: true,
                            onUpdateAddress: _ctx.updateAddress
                          }, null, 8, ["address", "onUpdateAddress"])
                        ])
                      ])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["isOpen"]),
              _createVNode(_component_CollapsibleSection, {
                isOpen: _ctx.sectionsStatus.orderDetails,
                header: "Order Details",
                "onUpdate:isOpen": _cache[18] || (_cache[18] = ($event: any) => (_ctx.toggleSectionIcon('orderDetails')))
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, [
                        _cache[57] || (_cache[57] = _createElementVNode("span", {
                          class: "p-inputgroup-addon",
                          style: { width: '103px' }
                        }, "Quote Date", -1)),
                        _createVNode(_component_Calendar, {
                          modelValue: _ctx.soQuote.date,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.soQuote.date) = $event)),
                          showIcon: true,
                          dateFormat: "mm-dd-yy",
                          manualInput: false,
                          onDateSelect: _cache[10] || (_cache[10] = ($event: any) => (_ctx.formatDate($event, 'QUOTED_DATE'))),
                          showButtonBar: true
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("div", _hoisted_33, [
                        _cache[58] || (_cache[58] = _createElementVNode("span", {
                          class: "p-inputgroup-addon",
                          style: { width: '103px' }
                        }, "Status Date", -1)),
                        _createVNode(_component_Calendar, {
                          modelValue: _ctx.soQuote.status_date,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.soQuote.status_date) = $event)),
                          showIcon: true,
                          dateFormat: "mm-dd-yy",
                          manualInput: false,
                          onDateSelect: _cache[12] || (_cache[12] = ($event: any) => (_ctx.formatDate($event, 'STATUS_DATE'))),
                          showButtonBar: true
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("div", _hoisted_35, [
                        _cache[59] || (_cache[59] = _createElementVNode("span", {
                          class: "p-inputgroup-addon",
                          style: { width: '103px' }
                        }, "Valid Thru", -1)),
                        _createVNode(_component_Calendar, {
                          modelValue: _ctx.soQuote.valid_thru,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.soQuote.valid_thru) = $event)),
                          showIcon: true,
                          dateFormat: "mm-dd-yy",
                          manualInput: false,
                          onDateSelect: _cache[14] || (_cache[14] = ($event: any) => (_ctx.formatDate($event, 'VALID_THRU_DATE'))),
                          showButtonBar: true
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _cache[63] || (_cache[63] = _createElementVNode("div", { class: "col-3 hidden xl:flex" }, null, -1)),
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("div", _hoisted_37, [
                        _cache[60] || (_cache[60] = _createElementVNode("span", {
                          class: "p-inputgroup-addon",
                          style: { width: '103px' }
                        }, " Quoted By ", -1)),
                        _createVNode(_component_Dropdown, {
                          modelValue: _ctx.selectedQuoter,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.selectedQuoter) = $event)),
                          options: _ctx.getUsers,
                          optionLabel: "user_name",
                          optionValue: "user_id",
                          dataKey: "user_id",
                          filter: true,
                          showClear: _ctx.showClear,
                          filterMatchMode: "startsWith",
                          onChange: _ctx.handleQuoterSelected
                        }, null, 8, ["modelValue", "options", "showClear", "onChange"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_38, [
                      _createElementVNode("div", _hoisted_39, [
                        _cache[61] || (_cache[61] = _createElementVNode("span", {
                          class: "p-inputgroup-addon",
                          style: { width: '103px' }
                        }, "Status", -1)),
                        _createVNode(_component_Dropdown, {
                          modelValue: _ctx.soQuote.status,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.soQuote.status) = $event)),
                          options: _ctx.status,
                          optionLabel: "description",
                          optionValue: "value"
                        }, null, 8, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_40, [
                      _createElementVNode("div", _hoisted_41, [
                        _cache[62] || (_cache[62] = _createElementVNode("span", {
                          class: "p-inputgroup-addon",
                          style: { width: '113px' }
                        }, "Est Close(%)", -1)),
                        _createVNode(_component_InputNumber, {
                          modelValue: _ctx.soQuote.est_close,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.soQuote.est_close) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["isOpen"]),
              _createVNode(_component_CollapsibleSection, {
                isOpen: _ctx.sectionsStatus.pricingShippingDetails,
                header: "Pricing & Shipping Details",
                "onUpdate:isOpen": _cache[25] || (_cache[25] = ($event: any) => (_ctx.toggleSectionIcon('pricingShippingDetails')))
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_42, [
                    _createElementVNode("div", _hoisted_43, [
                      _createElementVNode("div", _hoisted_44, [
                        _cache[64] || (_cache[64] = _createElementVNode("span", {
                          class: "p-inputgroup-addon",
                          style: { width: '113px' }
                        }, "Pay Terms", -1)),
                        _createVNode(_component_Dropdown, {
                          modelValue: this.soQuote.terms,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((this.soQuote.terms) = $event)),
                          options: _ctx.customerInquiry.terms,
                          optionLabel: "desc",
                          optionValue: "terms_id"
                        }, null, 8, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_45, [
                      _createElementVNode("div", _hoisted_46, [
                        _cache[65] || (_cache[65] = _createElementVNode("span", {
                          class: "p-inputgroup-addon",
                          style: { width: '113px' }
                        }, "Price Code", -1)),
                        _createVNode(_component_Dropdown, {
                          modelValue: this.soQuote.code,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((this.soQuote.code) = $event)),
                          options: _ctx.customerInquiry.pricingCodes,
                          optionLabel: "description",
                          optionValue: "code"
                        }, null, 8, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_47, [
                      _createElementVNode("div", _hoisted_48, [
                        _cache[66] || (_cache[66] = _createElementVNode("span", {
                          class: "p-inputgroup-addon",
                          style: { width: '113px' }
                        }, "Exch Rate", -1)),
                        _createVNode(_component_InputNumber, {
                          modelValue: _ctx.soQuote.exchange_rate,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.soQuote.exchange_rate) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_49, [
                      _createElementVNode("div", _hoisted_50, [
                        _cache[67] || (_cache[67] = _createElementVNode("span", {
                          class: "p-inputgroup-addon",
                          style: { width: '113px' }
                        }, "Price Method", -1)),
                        _createVNode(_component_Dropdown, {
                          modelValue: _ctx.soQuote.cost_method,
                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.soQuote.cost_method) = $event)),
                          options: _ctx.cost_method,
                          optionLabel: "description",
                          optionValue: "value"
                        }, null, 8, ["modelValue", "options"])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_51, [
                    _createElementVNode("div", _hoisted_52, [
                      _createVNode(_component_RepDataTable, {
                        reps: _ctx.soQuote.rep_items,
                        onUpdateReps: _ctx.updateReps
                      }, null, 8, ["reps", "onUpdateReps"])
                    ]),
                    _createElementVNode("div", _hoisted_53, [
                      _cache[68] || (_cache[68] = _createElementVNode("div", { class: "p-inputgroup mt-3 mb-2 font-semibold" }, " Notes ", -1)),
                      _createVNode(_component_Textarea, {
                        rows: "5",
                        modelValue: _ctx.soQuote.notes,
                        "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.soQuote.notes) = $event)),
                        placeholder: "Enter your note here...",
                        class: "opp-notes textarea mt-2 text-sm"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_54, [
                      _cache[69] || (_cache[69] = _createElementVNode("div", { class: "p-inputgroup mt-3 mb-2 font-semibold" }, " Confidential Notes ", -1)),
                      _createVNode(_component_Textarea, {
                        rows: "5",
                        modelValue: _ctx.soQuote.conf_notes,
                        "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.soQuote.conf_notes) = $event)),
                        placeholder: "Enter your note here...",
                        class: "opp-notes textarea mt-2 text-sm"
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["isOpen"])
            ]),
            _createElementVNode("div", _hoisted_55, [
              _createVNode(_component_DataTable, {
                value: _ctx.flatQuoteLineItems,
                responsiveLayout: "scroll",
                class: "quote-parts-table"
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_56, [
                    _cache[70] || (_cache[70] = _createElementVNode("h3", { class: "m-0 font-medium" }, "Line Items", -1)),
                    _createVNode(_component_Button, {
                      icon: "pi pi-plus",
                      label: "Add Part",
                      iconPos: "left",
                      onClick: _ctx.showPartModalDialog,
                      class: "h-2rem"
                    }, null, 8, ["onClick"])
                  ])
                ]),
                empty: _withCtx(() => _cache[71] || (_cache[71] = [
                  _createTextVNode(" No rows to show. ")
                ])),
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "part",
                    header: "Part"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createElementVNode("div", _hoisted_57, [
                        _createElementVNode("div", _hoisted_58, [
                          (slotProps.index === _ctx.editPartNumberIndex)
                            ? (_openBlock(), _createBlock(_component_Search, {
                                key: 0,
                                modelValue: _ctx.soQuote.li_items[_ctx.editPartNumberIndex].part,
                                "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.soQuote.li_items[_ctx.editPartNumberIndex].part) = $event)),
                                placeHolder: "Search for a Part",
                                onFilterPart: _cache[27] || (_cache[27] = ($event: any) => (_ctx.setPart($event.value))),
                                file: "PARTS",
                                label: "part_no",
                                class: "col-10 edit-part-number"
                              }, null, 8, ["modelValue"]))
                            : (_openBlock(), _createElementBlock("p", _hoisted_59, _toDisplayString(slotProps.data[slotProps.field]), 1)),
                          (slotProps.index === _ctx.editPartNumberIndex)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                _withDirectives(_createElementVNode("i", {
                                  class: "pi pi-times cursor-pointer",
                                  onClick: ($event: any) => (_ctx.resetEditPartNumberIndex(slotProps.index))
                                }, null, 8, _hoisted_60), [
                                  [_directive_tooltip, 'Close']
                                ]),
                                _withDirectives(_createElementVNode("i", {
                                  class: "pi pi-check cursor-pointer",
                                  onClick: ($event: any) => (_ctx.savePartNumber(slotProps.index))
                                }, null, 8, _hoisted_61), [
                                  [_directive_tooltip, 'Save']
                                ])
                              ], 64))
                            : _withDirectives((_openBlock(), _createElementBlock("i", {
                                key: 3,
                                class: "pi pi-pencil cursor-pointer",
                                onClick: ($event: any) => (_ctx.setEditPartNumberIndex(slotProps.index))
                              }, null, 8, _hoisted_62)), [
                                [_directive_tooltip, 'Edit']
                              ])
                        ]),
                        _createElementVNode("div", _hoisted_63, [
                          _createElementVNode("span", _hoisted_64, _toDisplayString(slotProps.data.wrap_desc), 1),
                          _withDirectives(_createElementVNode("i", {
                            class: "pi pi-pencil cursor-pointer",
                            onClick: ($event: any) => (_ctx.setEditpartIndex(slotProps.index))
                          }, null, 8, _hoisted_65), [
                            [_directive_tooltip, 'Edit']
                          ])
                        ])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "qty",
                    header: "Quantity",
                    headerClass: "right-align",
                    bodyStyle: "text-align:right",
                    style: { minWidth: '70px', maxWidth: '140px' }
                  }, {
                    body: _withCtx((slotProps) => [
                      _createVNode(_component_InputNumber, {
                        inputStyle: "min-width:50px; max-width:100px; border-radius: 0px !important;",
                        showButtons: "",
                        buttonLayout: "horizontal",
                        incrementButtonIcon: "pi pi-plus",
                        decrementButtonIcon: "pi pi-minus",
                        modelValue: slotProps.data[slotProps.field],
                        "onUpdate:modelValue": ($event: any) => ((slotProps.data[slotProps.field]) = $event),
                        min: 0,
                        maxFractionDigits: 4,
                        onInput: ($event: any) => (_ctx.updateQuantity(slotProps, $event)),
                        class: "h-2rem",
                        inputClass: "h-2rem"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "price",
                    header: "Price",
                    headerClass: "right-align",
                    bodyStyle: "text-align:right",
                    style: { minWidth: '70px', maxWidth: '100px' }
                  }, {
                    body: _withCtx((slotProps) => [
                      _createVNode(_component_InputNumber, {
                        modelValue: slotProps.data[slotProps.field],
                        "onUpdate:modelValue": ($event: any) => ((slotProps.data[slotProps.field]) = $event),
                        min: 0,
                        onInput: ($event: any) => (_ctx.updatePrice(slotProps, $event)),
                        mode: "decimal",
                        minFractionDigits: 2,
                        maxFractionDigits: 4,
                        inputStyle: "min-width:70px;",
                        inputClass: "h-2rem lineitem-price-field"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "disc_pct",
                    header: "Discount %",
                    headerClass: "right-align",
                    bodyStyle: "text-align:right",
                    style: { minWidth: '70px', maxWidth: '100px' }
                  }, {
                    body: _withCtx((slotProps) => [
                      (slotProps.data.index === 0)
                        ? (_openBlock(), _createBlock(_component_InputNumber, {
                            key: 0,
                            modelValue: slotProps.data[slotProps.field],
                            "onUpdate:modelValue": ($event: any) => ((slotProps.data[slotProps.field]) = $event),
                            min: 0,
                            onInput: ($event: any) => (_ctx.updateDiscPercent(slotProps, $event)),
                            mode: "decimal",
                            minFractionDigits: 2,
                            maxFractionDigits: 4,
                            inputStyle: "min-width:50px;",
                            inputClass: "h-2rem lineitem-disc-field"
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_66, " - "))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "extended_price",
                    header: "Extended Price",
                    headerClass: "right-align",
                    bodyStyle: "text-align:right",
                    style: { minWidth: '50px', maxWidth: '100px' }
                  }, {
                    body: _withCtx((slotProps) => [
                      _createTextVNode(_toDisplayString(this.formatCurrency(slotProps.data[slotProps.field])), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    header: "",
                    headerClass: "left-align",
                    bodyStyle: "text-align:center"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createVNode(_component_Button, {
                        onClick: _withModifiers(($event: any) => (_ctx.removePart(slotProps)), ["stop"]),
                        icon: "pi pi-trash",
                        style: {"background-color":"red !important"},
                        class: "m-0"
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ColumnGroup, { type: "footer" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Column, {
                            footer: 
                      'Total: ' + this.formatCurrency(this.calculatedTotal)
                    ,
                            colspan: 5,
                            footerStyle: "text-align:right"
                          }, null, 8, ["footer"]),
                          _createVNode(_component_Column, { colspan: 1 })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"]),
              (_ctx.soQuote.id)
                ? (_openBlock(), _createBlock(_component_CollapsibleSection, {
                    key: 0,
                    isOpen: _ctx.sectionsStatus.logEntries,
                    header: "Log Entries",
                    "onUpdate:isOpen": _cache[28] || (_cache[28] = ($event: any) => (_ctx.toggleSectionIcon('logEntries')))
                  }, {
                    content: _withCtx(() => [
                      _createElementVNode("div", _hoisted_67, [
                        _createVNode(_component_LogsDataTable, {
                          elementId: _ctx.soQuote.id,
                          file: "SOQUOTE",
                          associatedTypes: _ctx.associatedTypes
                        }, null, 8, ["elementId", "associatedTypes"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["isOpen"]))
                : _createCommentVNode("", true),
              (_ctx.soQuote.id)
                ? (_openBlock(), _createBlock(_component_CollapsibleSection, {
                    key: 1,
                    isOpen: _ctx.sectionsStatus.attachments,
                    header: "Attachments",
                    "onUpdate:isOpen": _cache[29] || (_cache[29] = ($event: any) => (_ctx.toggleSectionIcon('attachments')))
                  }, {
                    content: _withCtx(() => [
                      _createElementVNode("div", _hoisted_68, [
                        _createVNode(_component_AttachmentRecords, {
                          class: "pt-0",
                          hostFile: "SOQUOTE",
                          record: _ctx.soQuote,
                          isLogHidden: false
                        }, null, 8, ["record"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["isOpen"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_SearchPartDialog, {
          show: _ctx.showPartSearchDialog,
          companyCode: _ctx.soQuote.co_code,
          onCloseDialog: _ctx.closeSearchPartDialog,
          onAddToCart: _ctx.addItem
        }, null, 8, ["show", "companyCode", "onCloseDialog", "onAddToCart"]),
        _createVNode(_component_Dialog, {
          visible: this.showAddExistingQuoteDialog,
          "onUpdate:visible": _cache[33] || (_cache[33] = ($event: any) => ((this.showAddExistingQuoteDialog) = $event)),
          header: "Search for Quote",
          modal: true,
          style: { width: '500px' },
          onHide: _cache[34] || (_cache[34] = 
          () => {
            this.resetFields(`QUOTE`);
          }
        ),
          onShow: _cache[35] || (_cache[35] = 
          () => {
            this.focusSOQuoteSearch();
          }
        )
        }, {
          footer: _withCtx(() => [
            _createVNode(_component_Button, {
              label: "Cancel",
              icon: "pi pi-times",
              onClick: _cache[31] || (_cache[31] = 
              () => {
                this.showAddExistingQuoteDialog = false;
              }
            ),
              class: "p-button-text"
            }),
            _createVNode(_component_Button, {
              label: "Populate Quote",
              icon: "pi pi-check",
              onClick: _cache[32] || (_cache[32] = ($event: any) => (_ctx.populateDialog())),
              loading: this.isLoadingSOQuote
            }, null, 8, ["loading"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              ref: "soquoteInputText",
              placeholder: "Search by Id",
              modelValue: this.existingSOQuote,
              "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((this.existingSOQuote) = $event))
            }, null, 8, ["modelValue"]),
            _cache[72] || (_cache[72] = _createTextVNode("   ")),
            (_ctx.isOrderEmpty && !!this.statusMessage)
              ? (_openBlock(), _createElementBlock("small", _hoisted_69, _toDisplayString(this.statusMessage), 1))
              : _createCommentVNode("", true),
            (
            _ctx.isLoadQuoteFormSubmitted && _ctx.v$.existingSOQuote.required.$invalid
          )
              ? (_openBlock(), _createElementBlock("small", _hoisted_70, _toDisplayString(_ctx.v$.existingSOQuote.required.$message), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["visible"]),
        _createVNode(_component_Dialog, {
          visible: this.showAddOpportunityDialog,
          "onUpdate:visible": _cache[39] || (_cache[39] = ($event: any) => ((this.showAddOpportunityDialog) = $event)),
          header: "Search for Opportunity",
          modal: true,
          style: { width: '500px' },
          onHide: _cache[40] || (_cache[40] = 
          () => {
            this.resetFields(`OPPS`);
          }
        ),
          onShow: _cache[41] || (_cache[41] = 
          () => {
            this.focusOpportunitySearch();
          }
        )
        }, {
          footer: _withCtx(() => [
            _createVNode(_component_Button, {
              label: "Cancel",
              icon: "pi pi-times",
              onClick: _cache[37] || (_cache[37] = 
              () => {
                this.showAddOpportunityDialog = false;
              }
            ),
              class: "p-button-text"
            }),
            _createVNode(_component_Button, {
              label: "Populate Opportunity",
              icon: "pi pi-check",
              onClick: _cache[38] || (_cache[38] = ($event: any) => (_ctx.populateDialogWithOpp())),
              loading: this.isLoadingOpportunity
            }, null, 8, ["loading"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              ref: "opportunityInputText",
              placeholder: "Search by Id",
              modelValue: this.existingOpportunity,
              "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((this.existingOpportunity) = $event))
            }, null, 8, ["modelValue"]),
            _cache[73] || (_cache[73] = _createTextVNode("   ")),
            (_ctx.isOpportunityEmpty && !!this.statusMessage)
              ? (_openBlock(), _createElementBlock("small", _hoisted_71, _toDisplayString(this.statusMessage), 1))
              : _createCommentVNode("", true),
            (
            _ctx.isLoadOppFormSubmitted && _ctx.v$.existingOpportunity.required.$invalid
          )
              ? (_openBlock(), _createElementBlock("small", _hoisted_72, _toDisplayString(_ctx.v$.existingOpportunity.required.$message), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["visible"])
      ])
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showChangeCustomerDialog,
      "onUpdate:visible": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.showChangeCustomerDialog) = $event)),
      header: "Clear Customer Details"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "No",
          icon: "pi pi-times",
          onClick: _cache[42] || (_cache[42] = 
          () => {
            _ctx.showChangeCustomerDialog = false;
          }
        ),
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Yes",
          icon: "pi pi-check",
          onClick: _ctx.confirmChangeCustomer
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _cache[74] || (_cache[74] = _createElementVNode("p", null, "Would you like to clear The Customer Details?", -1))
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showEditPartDescriptionDialog,
      "onUpdate:visible": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.showEditPartDescriptionDialog) = $event)),
      header: "Edit Part Description",
      style: { width: '450px' },
      onHide: _ctx.onCloseEditPartDescription,
      modal: ""
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _ctx.resetEditpartIndex
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          label: "Save",
          icon: "pi pi-check",
          class: "p-button-text",
          onClick: _ctx.savePartDescription
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Textarea, {
          rows: "3",
          class: "opp-notes",
          placeholder: "Enter Part Description",
          modelValue: _ctx.flatQuoteLineItems[_ctx.editPartIndex].wrap_desc,
          "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.flatQuoteLineItems[_ctx.editPartIndex].wrap_desc) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["visible", "onHide"]),
    _createVNode(_component_EmailFileDialog, {
      show: _ctx.showEmailDialog,
      header: 'Email Quote ' + _ctx.soQuote.id,
      onHide: _cache[46] || (_cache[46] = 
      (isHidden) => {
        _ctx.showEmailDialog = isHidden;
      }
    ),
      fileId: _ctx.soQuote.id,
      fileName: _ctx.soQuote.id,
      onOnEmail: _ctx.sendEmail
    }, null, 8, ["show", "header", "fileId", "fileName", "onOnEmail"])
  ], 64))
}